import React, { createContext, useReducer, useMemo, useContext } from "react";
import { useIntl } from "react-intl";
import { serverBase } from "../utility/axios";
import { getUser } from "../utility/Common";

const moneyRequestContext = createContext();
const initialMoneyRequestContext = [];

const MoneyRequestReducer = (state, action) => {
  switch (action.type) {
    case "SET_MONREQ":
      return action.payload;
    case "MONREQ_READ":
      return state.map((s) => ({ ...s, isSeen: true }));
    case "UNSET_MONREQ":
      return initialMoneyRequestContext;
    default:
      return initialMoneyRequestContext;
  }
};

export const MoneyRequestProvider = (props) => {
  const [state, dispatch] = useReducer(MoneyRequestReducer, initialMoneyRequestContext);
  const value = useMemo(() => [state, dispatch], [state]);
  return <moneyRequestContext.Provider value={value} {...props} />;
};

export const useMonreq = () => {
  const context = useContext(moneyRequestContext);
  if (!context) {
    throw new Error(`useTask must be used within a MoneyRequestProvider`);
  }
  const [state, dispatch] = context;
  const intl = useIntl()

  const getAllMoneyRequest = async (_id) => {
    const user = getUser();
    await serverBase(`money-request/list?_id=${user._id}`).then((res) => {
      if (res.status === 200) {
        const filterTaskDeleted = res.data.filter((result) => {
          // In Routine task message approval using intl transalate
          if (result.task.isRoutine) {
            result.approval.manager = {...result.approval.manager,message:intl.messages['Routine Task']} 
            result.approval.admin = {...result.approval.admin,message:intl.messages['Routine Task']} 
            result.approval.accountant = {...result.approval.accountant,message:intl.messages['Routine Task']} 
            result.approval.decision = {...result.approval.decision,message:intl.messages['Routine Task']} 
          }
          return result.task.is_deleted === false;
        });
        dispatch({ type: "SET_MONREQ", payload: filterTaskDeleted });
      }
    });
  };

  const markReadMonReq = async (data) => {
    // const res = await serverBase.post(`task/mark-read`, data).catch(err => {
    const res = await serverBase.post(`money-request/bulk-mark-read`, data).catch((err) => {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
      }
    });
    if (res && res.data === "success") {
      dispatch({ type: "MONREQ_READ" });
    }
    return res;
  };

  const dumpAllMoneyRequest = () => {
    dispatch({ type: "UNSET_MONREQ" });
  };

  return {
    monreq: state,
    getAllMoneyRequest,
    dumpAllMoneyRequest,
    markReadMonReq,
  };
};
