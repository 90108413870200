import React, { createContext, useReducer, useMemo, useContext } from "react";
import _ from "lodash";
import { serverBase } from "../utility/axios";
import { getUser } from "../utility/Common";

const MailContext = createContext();

const initialMailState = [];

const MailReducer = (state, action) => {
  switch (action.type) {
    case "SET_MAIL":
      return action.payload;
    case "UNSET_MAIL":
      return initialMailState;
    default:
      return initialMailState;
  }
};

export const MailProvider = (props) => {
  const [state, dispatch] = useReducer(MailReducer, initialMailState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <MailContext.Provider value={value} {...props} />;
};

export const useMail = () => {
  const context = useContext(MailContext);
  if (!context) {
    throw new Error(`useMail must be used within a MailProvider`);
  }
  const [state, dispatch] = context;

  const getAllEmail = async (_id) => {
    const res = await serverBase.get(`email/mails?_id=${_id}`);
    if (res.status === 200 && res.data) {
      dispatch({ type: "SET_MAIL", payload: res.data });
    }
  };

  const dumpAllEmail = () => {
    dispatch({ type: "UNSET_MAIL" });
  };

  const changeFolder = async (data) => {
    const newMails = state.map((s) => {
      const find = _.find(data, ["_id", s._id]);
      if (find) {
        return { ...s, mailFolder: find.mailFolder };
      }
      return s;
    });
    dispatch({ type: "SET_MAIL", payload: newMails });
    try {
      await serverBase.post("email/change-folder", { data }).catch((error) => {
        if (error.response) {
          alert(error.response.data);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const changeLabel = async (data) => {
    const newMails = state.map((s) => {
      const find = _.find(data, ["_id", s._id]);
      if (find) {
        return { ...s, labels: find.labels };
      }
      return s;
    });
    dispatch({ type: "SET_MAIL", payload: newMails });
    try {
      await serverBase.post("email/change-label", { data }).catch((error) => {
        if (error.response) {
          alert(error.response.data);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const changeRead = async (data) => {
    const newMails = state.map((s) => {
      const find = _.find(data, ["_id", s._id]);
      if (find) {
        return { ...s, unread: find.unread };
      }
      return s;
    });
    dispatch({ type: "SET_MAIL", payload: newMails });
    try {
      await serverBase.post("email/change-read", { data }).catch((error) => {
        if (error.response) {
          alert(error.response.data);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const changeStar = async (_id, isStarred) => {
    const newMails = state.map((s) => {
      if (s._id === _id) {
        return { ...s, isStarred };
      }
      return s;
    });
    dispatch({ type: "SET_MAIL", payload: newMails });
    try {
      await serverBase.post("email/change-star", { _id, isStarred }).catch((error) => {
        if (error.response) {
          alert(error.response.data);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const hardDelete = async (list) => {
    try {
      await serverBase.post("email/hard-delete", { list }).catch((error) => {
        if (error.response) {
          alert(error.response.data);
        }
      });
      getAllEmail(getUser()._id);
    } catch (err) {
      console.error(err);
    }
  };

  return {
    mailState: state,
    getAllEmail,
    dumpAllEmail,
    changeFolder,
    changeLabel,
    changeRead,
    changeStar,
    hardDelete,
  };
};
