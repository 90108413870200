import React, { createContext, useReducer, useMemo, useContext } from "react"
import { io } from "socket.io-client";
import { socketBase } from "../utility/axios"

const AuthContext = createContext();
const socket = io(socketBase, {autoConnect: false});

const initialAuthState = false;

const AuthReducer = (state, action) => {
  switch(action.type) {
    case "SET_AUTH": 
      return action.payload
    case "UNSET_AUTH":
      return initialAuthState
    default:
      return initialAuthState
  }
}

export const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(AuthReducer, initialAuthState)
  const value = useMemo(() => [state, dispatch], [state])
  return <AuthContext.Provider value={value} {...props} />
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if(!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  const [state, dispatch] = context

  const login = (payload) => {
    socket.connect();
    setTimeout(() => {
      socket.emit("identify", {_id: payload._id, role: payload.role})
    }, 1000)
    dispatch({ type: "SET_AUTH", payload})
  }

  const socketListener = (payload) => {
    socket.on(payload.name, (data) => payload.listener(data));
  };

  const socketExit = () => {
    socket.emit("exit", state);
    setTimeout(() => {
      socket.disconnect()
    }, 1000);
  };

  const logout = () => {
    dispatch({ type: "UNSET_AUTH" })
  }

  return {
    authState: state,
    login,
    logout,
    socketListener,
    socketExit
  }
}
