import React, { createContext, useReducer, useMemo, useContext } from 'react'
import { serverBase } from '../utility/axios'
// import { getUser } from '../utility/Common'

const NotificationContext = createContext()

const initialNotificationState = {
  chat: 0,
  email: 0,
  quickTask: 0,
  task: 0,
  monreq: 0
}

const NotificationReducer = (state, action) => {
  switch (action.type) {
    case 'UNSET_NOTIF':
      return initialNotificationState
    case 'SET_CHAT_NOTIF':
      return { ...state, chat: action.payload }
    case 'SET_EMAIL_NOTIF':
      return { ...state, email: action.payload }
    case 'SET_QUICKTASK_NOTIF':
      return { ...state, quickTask: action.payload }
    case 'SET_TASK_NOTIF':
      return { ...state, task: action.payload }
    case 'SET_MONREQ_NOTIF':
      return { ...state, monreq: action.payload }
    default:
      return initialNotificationState
  }
}

export const NotificationProvider = props => {
  const [state, dispatch] = useReducer(
    NotificationReducer,
    initialNotificationState
  )
  const value = useMemo(() => [state, dispatch], [state])
  return <NotificationContext.Provider value={value} {...props} />
}

export const useNotif = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error(`useNotif must be used within a NotificationProvider`)
  }
  const [state, dispatch] = context

  const dumpAllNotification = async (notifications, user) => {
    if (notifications) {
      const tasks =
        notifications.task &&
        [...notifications.task].map(task => task.isSeen === false && task._id)
      const monreq =
        notifications.monreq &&
        [...notifications.monreq].map(mon => mon.isSeen === false && mon._id)
      const quickTasks =
        notifications.quickTask &&
        [...notifications.quickTask].map(quickTask => quickTask._id)
      const emails =
        notifications.email && [...notifications.email].map(email => email._id)
      const chats =
        notifications.chat && [...notifications.chat].map(chat => chat._id)

      try {
        if (tasks) {
          await serverBase.post('money-request/bulk-mark-read', {
            monreq,
            user
          })
        }
        if (tasks) {
          await serverBase.post('task/bulk-mark-read', { tasks, user })
        }

        if (quickTasks) {
          await serverBase.post('quick-task/bulk-mark-read', {
            quickTasks,
            user
          })
        }

        if (emails) {
          await serverBase.post('email/bulk-mark-read', { emails, user })
        }

        if (chats) {
          await serverBase.post('chat/bulk-mark-read', { chats, user })
        }
        dispatch({ type: 'UNSET_NOTIF' })
      } catch (err) {
        console.error(err)
      }
    }
  }

  const newEmailNotif = (_id, allEmail) => {
    const unread = allEmail.filter(
      mail => mail?.to._id === _id && mail.unread === true
    )
    dispatch({ type: 'SET_EMAIL_NOTIF', payload: unread })
  }

  const newChatNotif = (_id, allChat) => {
    const unseen = []
    allChat.forEach(chat => {
      chat.contents.forEach(content => {
        if (content?.to === _id && !content.isSeen) unseen.push(content)
      })
    })
    dispatch({ type: 'SET_CHAT_NOTIF', payload: unseen })
  }

  const newQTNotif = allQT => {
    const unseen = []
    allQT.forEach(task => {
      if (!task.isSeen) unseen.push(task)
    })
    dispatch({ type: 'SET_QUICKTASK_NOTIF', payload: unseen })
  }

  const newTaskNotif = allTask => {
    const unseen = []

    allTask.forEach(task => {
      if (!task.isSeen) unseen.push(task)
    })
    dispatch({ type: 'SET_TASK_NOTIF', payload: unseen })
  }

  const newMonReq = allMonreq => {
    const unseen = []

    allMonreq.forEach(mon => {
      if (!mon.isSeen) unseen.push(mon)
    })
    dispatch({ type: 'SET_MONREQ_NOTIF', payload: unseen })
  }

  return {
    notifications: state,
    dumpAllNotification,
    newEmailNotif,
    newChatNotif,
    newQTNotif,
    newTaskNotif,
    newMonReq
  }
}
