import React, { createContext, useReducer, useMemo, useContext } from "react";
import { serverBase } from "../utility/axios";
import { useAuth } from "./authContext";

const QuickTaskContext = createContext();
const initialQuickTaskState = [];

const QuickTaskReducer = (state, action) => {
  switch (action.type) {
    case "SET_QUICK_TASK":
      return action.payload;
    case "QUICK_TASK_READ":
      return state.map((s) => ({ ...s, isSeen: true }));
    case "UNSET_QUICK_TASK":
      return initialQuickTaskState;
    default:
      return initialQuickTaskState;
  }
};

export const QuickTaskProvider = (props) => {
  const [state, dispatch] = useReducer(QuickTaskReducer, initialQuickTaskState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <QuickTaskContext.Provider value={value} {...props} />;
};

export const useQuickTask = () => {
  const context = useContext(QuickTaskContext);
  const { authState } = useAuth();

  if (!context) {
    throw new Error(`useQuickTask must be used within a QuickTaskProvider`);
  }
  const [state, dispatch] = context;

  const getAllQuickTask = async (_id) => {
    const res = await serverBase.get(`quick-task/list?_id=${_id}`);
    if (res.status === 200 && res.data) {
      dispatch({ type: "SET_QUICK_TASK", payload: res.data });
    }
  };

  const dumpAllQuickTask = () => {
    dispatch({ type: "UNSET_QUICK_TASK" });
  };

  const addQuickTask = async (data, user) => {
    const res = await serverBase
      .post(`quick-task/add`, {
        title: data.title,
        from: { _id: user._id, role: user.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        finishTime: data.finishTime,
        description: data.description,
        isImportant: data.isImportant,
        isStarred: data.isStarred,
        labels: data.labels,
        currency: data.currency,
        amount: data.amount,
        file: data.file,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    return res;
  };

  const editQuickTask = async (data) => {
    const res = await serverBase
      .post(`quick-task/edit`, {
        _id: data._id,
        title: data.title,
        from: { _id: data.from._id, role: data.from.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        finishTime: data.finishTime,
        description: data.description,
        isImportant: data.isImportant,
        isStarred: data.isStarred,
        currency: data.currency,
        amount: data.amount,
        labels: data.labels,
        file: data.file,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    return res;
  };

  const transferQuickTask = async (_id, assigned) => {
    const res = await serverBase
      .post(`quick-task/transfer/${_id}`, {
        assigned: assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    return res;
  };

  const starQuickTask = async (data) => {
    const res = await serverBase
      .post(`quick-task/star`, {
        _id: data._id,
        from: { _id: data.from._id, role: data.from.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        isStarred: !data.isStarred,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    getAllQuickTask(authState._id);
    return res;
  };

  const importantQuickTask = async (data) => {
    const res = await serverBase
      .post(`quick-task/important`, {
        _id: data._id,
        from: { _id: data.from._id, role: data.from.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        isImportant: !data.isImportant,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    getAllQuickTask(authState._id);
    return res;
  };

  const deleteQuickTask = async (data) => {
    const res = await serverBase
      .post(`quick-task/delete`, {
        _id: data._id,
        from: { _id: data.from._id, role: data.from.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        isDeleted: !data.isDeleted,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    getAllQuickTask(authState._id);
    return res;
  };

  const approvalTask = async (data) => {
    const res = await serverBase
      .post(`quick-task/approval`, {
        _id: data._id,
        from: { _id: data.from._id, role: data.from.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        isApprovalRequired: !data.isApprovalRequired,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    return res;
  };

  const completeTask = async (data) => {
    const res = await serverBase
      .post(`quick-task/complete`, {
        _id: data._id,
        from: { _id: data.from._id, role: data.from.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        isCompleted: !data.isCompleted,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    return res;
  };

  const notAcceptTask = async (data) => {
    const res = await serverBase
      .post(`quick-task/not-accept`, {
        _id: data._id,
        from: { _id: data.from._id, role: data.from.role },
        assigned: data.assigned.map((ass) => ({
          _id: ass.value,
          role: ass.role,
        })),
        isNotAccepted: !data.isNotAccepted,
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
    return res;
  };

  const markReadTask = async (data) => {
    const res = await serverBase.post(`quick-task/mark-read`, data).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
    if (res && res.data === "success") {
      dispatch({ type: "QUICK_TASK_READ" });
    }
    return res;
  };

  return {
    quickTasks: state,
    getAllQuickTask,
    dumpAllQuickTask,
    addQuickTask,
    transferQuickTask,
    editQuickTask,
    starQuickTask,
    importantQuickTask,
    deleteQuickTask,
    approvalTask,
    completeTask,
    notAcceptTask,
    markReadTask,
  };
};
