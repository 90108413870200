import React, { PureComponent } from "react";
import themeConfig from "../configs/themeConfig";
import classnames from "classnames";
import "../assets/scss/components/scrollbar-theme.scss";

class FullPageLayout extends PureComponent {
  componentDidMount() {
    document.body.classList.add("dark-layout");
  }
  render() {
    return (
      <div
        className={classnames(
          "vertical-layout vertical-menu-modern blank-page navbar-floating footer-static  dark-layout  pace-done",
          {
            "layout-dark": themeConfig.theme,
          }
        )}
      >
        <div className="app-content">
          <div className="content-wrapper">
            <div className="content-body" style={{ height: "100%" }}>
              <div className="flexbox-container">
                <main
                  className="main w-100 scrollbar-theme"
                  style={{ height: "100%", overflow: "scroll" }}
                >
                  {this.props.children}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FullPageLayout;
