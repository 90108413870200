import React, { createContext, useReducer, useMemo, useContext } from "react";
import { serverBase } from "../utility/axios";

const ContactContext = createContext();

const initialContactState = { self: {}, contacts: [] };

const ContactReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTACT":
      return action.payload;
    case "UNSET_CONTACT":
      return initialContactState;
    case "CONTACT_CHANGED":
      if (action.payload.field === "self") {
        return {
          ...state,
          [action.payload.field]: {
            ...state[action.payload.field],
            [action.payload.type]: action.payload[action.payload.type],
          },
        };
      } else {
        return {
          ...state,
          [action.payload.field]: state[action.payload.field].map((contact) => {
            if (contact._id === action.payload._id) {
              return {
                ...contact,
                [action.payload.type]: action.payload[action.payload.type],
              };
            }
            return contact;
          }),
        };
      }
    default:
      return initialContactState;
  }
};

export const ContactProvider = (props) => {
  const [state, dispatch] = useReducer(ContactReducer, initialContactState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <ContactContext.Provider value={value} {...props} />;
};

export const useContact = () => {
  const context = useContext(ContactContext);
  if (!context) {
    throw new Error(`useContact must be used within a ContactProvider`);
  }
  const [state, dispatch] = context;

  const getAllContact = async (_id) => {
    const res = await serverBase.get(`contact/all`);
    if (res.status === 200 && res.data) {
      let self = {};
      let contacts = [...res.data];
      //
      let selfIndex = res.data.findIndex((d) => d._id === _id);
      if (selfIndex > -1) {
        self = contacts[selfIndex];
        contacts.splice(selfIndex, 1);
      } else {
        contacts = res.data;
      }
      dispatch({ type: "SET_CONTACT", payload: { self, contacts } });
    }
  };

  const dumpAllContact = () => {
    dispatch({ type: "UNSET_CONTACT" });
  };

  const changeStatus = async (payload) => {
    await serverBase.post("contact/change-status", { ...payload }).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
  };

  const changeDesc = async (payload) => {
    await serverBase.post("contact/change-desc", { ...payload }).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
  };

  const onContactChange = (_id, data) => {
    dispatch({
      type: "CONTACT_CHANGED",
      payload: { ...data, field: data._id === _id ? "self" : "contacts" },
    });
  };

  return {
    selfContact: state.self,
    contacts: state.contacts,
    getAllContact,
    dumpAllContact,
    changeStatus,
    changeDesc,
    onContactChange,
  };
};
