import React, { createContext, useReducer, useMemo, useContext } from "react";
import moment from "moment";
import { serverBase } from "../utility/axios";

const CalendarContext = createContext();
const initialCalendarState = {
  events: [],
  sidebar: false,
  selected: null,
};

const TaskReducer = (state, action) => {
  switch (action.type) {
    case "SET_CALENDAR_EVENTS":
      return { ...state, events: action.payload };
    case "SET_CALENDAR_SIDEBAR":
      return { ...state, sidebar: action.payload };
    case "SET_CALENDAR_SELECTED":
      return { ...state, selected: action.payload };
    case "SET_CALENDAR_DRAG":
      return {
        ...state,
        events: state.events.map((s) => {
          if (s._id === action.payload._id) {
            return {
              ...s,
              start: action.payload.start,
              end: action.payload.end,
              allDay: action.payload.allDay,
            };
          } else {
            return s;
          }
        }),
      };
    case "SET_CALENDAR_RESIZE":
      return {
        ...state,
        events: state.events.map((s) => {
          if (s._id === action.payload._id) {
            return {
              ...s,
              start: action.payload.start,
              end: action.payload.end,
            };
          } else {
            return s;
          }
        }),
      };
    case "UNSET_CALENDAR":
      return initialCalendarState;
    default:
      return initialCalendarState;
  }
};

export const CalendarProvider = (props) => {
  const [state, dispatch] = useReducer(TaskReducer, initialCalendarState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <CalendarContext.Provider value={value} {...props} />;
};

export const useCalendar = () => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error(`useCalendar must be used within a CalendarProvider`);
  }
  const [state, dispatch] = context;

  const getAllCalendar = async (_id) => {
    const res = await serverBase.get(`calendar/list?_id=${_id}`);
    if (res.status === 200 && res.data) {
      dispatch({
        type: "SET_CALENDAR_EVENTS",
        payload: res.data
          .map((data) => ({
            ...data,
            start: moment(data.start).toDate(),
            end: moment(data.end).toDate(),
          }))
          .filter((cal) => cal.title !== ""),
      });
    }
  };

  const dumpAllCalendar = () => {
    dispatch({ type: "UNSET_CALENDAR" });
  };

  const changeCalendarSidebar = (payload) => {
    dispatch({ type: "SET_CALENDAR_SIDEBAR", payload });
  };

  const changeCalendarSelected = (payload) => {
    dispatch({ type: "SET_CALENDAR_SELECTED", payload });
  };

  const addEvent = async (payload) => {
    const res = await serverBase.post(`calendar/add`, payload).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
    return res;
  };

  const editEvent = async (payload) => {
    const res = await serverBase.post(`calendar/edit`, payload).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
    return res;
  };

  const updateDrag = async (payload) => {
    dispatch({ type: "SET_CALENDAR_DRAG", payload });
    await serverBase.post(`calendar/edit`, payload).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
  };

  const updateResize = async (payload) => {
    dispatch({ type: "SET_CALENDAR_RESIZE", payload });
    await serverBase.post(`calendar/edit`, payload).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
  };

  return {
    calendarEvents: state.events,
    calendarSidebar: state.sidebar,
    calendarSelected: state.selected,
    getAllCalendar,
    dumpAllCalendar,
    changeCalendarSidebar,
    changeCalendarSelected,
    addEvent,
    editEvent,
    updateDrag,
    updateResize,
  };
};
