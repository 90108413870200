import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDom from "react-dom";
import { useIntl } from "react-intl";
import { IntlProviderWrapper } from "../../../utility/context/Internationalization";

const CustomAlertComponent = ({
  isConfirm,
  sub_message,
  message,
  intlMessage,
  intlMessageParam,
  onConfirm,
  confirmBtnText,
  cancelBtnText,
  onCancel = null,
}) => {
  const intl = useIntl();
  const confirmText = !confirmBtnText
    ? isConfirm
      ? intl.messages["button.simple_alert.confirm"]
      : intl.messages["button.simple_alert.ok"]
    : confirmBtnText;
  const cancelText = !cancelBtnText ? intl.messages["button.simple_alert.cancel"] : cancelBtnText;
  return (
    <SweetAlert
      warning
      confirmBtnBsStyle="success"
      confirmBtnText={confirmText}
      cancelBtnBsStyle="danger"
      cancelBtnText={cancelText}
      showCancel={isConfirm}
      title={intlMessage ? intl.formatMessage({ id: intlMessage }, intlMessageParam) : message}
      show={true}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <p className="sweet-alert-text">{sub_message}</p>
    </SweetAlert>
  );
};

const SimpleAlert = (params) => {
  let defaultObj = {
    isConfirm: false,
    sub_message: "",
    message: "",
    intlMessage: "",
    intlMessageParam: "",
    confirmBtnText: "",
    cancelBtnText: "",
    onConfirm: null,
    onCancel: null,
  };

  if (typeof params === "object") {
    defaultObj = params;
  } else {
    defaultObj.message = params;
  }

  const {
    isConfirm,
    sub_message,
    message,
    onConfirm,
    onCancel,
    intlMessage,
    intlMessageParam,
    confirmBtnText,
    cancelBtnText,
  } = defaultObj;

  const alert = document.createElement("div");
  document.body.appendChild(alert);
  ReactDom.render(
    <IntlProviderWrapper>
      <CustomAlertComponent
        isConfirm={isConfirm}
        sub_message={sub_message}
        message={message}
        intlMessage={intlMessage}
        intlMessageParam={intlMessageParam}
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        onConfirm={() => {
          if (onConfirm) onConfirm();
          alert.remove();
        }}
        onCancel={() => {
          if (onCancel) onCancel();
          alert.remove();
        }}
      />
    </IntlProviderWrapper>,
    alert
  );
};

export default SimpleAlert;
