import React from 'react'
import ScrollToTop from 'react-scroll-up'
import { Button } from 'reactstrap'
import { ArrowUp } from 'react-feather'
import classnames from 'classnames'
import themeConfig from '../../../configs/themeConfig'
import { FormattedMessage } from 'react-intl'

const Footer = props => {
  let footerTypeArr = ['sticky', 'static', 'hidden']
  return (
    <footer
      className={classnames(
        'footer footer-light d-none d-lg-block d-md-block',
        {
          'footer-static':
            props.footerType === 'static' ||
            !footerTypeArr.includes(props.footerType),
          'd-none': props.footerType === 'hidden'
        }
      )}>
      <p className='mb-0 clearfix'>
        <span className='float-md-left d-block d-md-inline-block mt-25'>
          <FormattedMessage id={'copyrightspart'} />
          <FormattedMessage id={'copyrights'} /> © {new Date().getFullYear()}
        </span>
        {/* <span className="float-md-right d-none d-md-block">
          <span className="align-middle">Hand-crafted & Made with</span>{" "}
          <Heart className="text-danger" size={15} />
        </span> */}
      </p>
      {themeConfig.hideScrollToTop === 'False' ? (
        <ScrollToTop showUnder={160}>
          <Button color='primary' className='btn-icon scroll-top'>
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : (
        ''
      )}
    </footer>
  )
}

export default Footer
