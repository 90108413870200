import axios from 'axios'

const serverBase = axios.create({
  baseURL:
    process.env.REACT_APP_MODE === 'development'
      ? process.env.REACT_APP_TES_SERVER
      : process.env.REACT_APP_MODE === 'production'
      ? process.env.REACT_APP_PROD_SERVER
      : process.env.REACT_APP_TES_SERVER
})

const assetBase =
  process.env.REACT_APP_MODE === 'development'
    ? `${process.env.REACT_APP_TES_SERVER}avatars/`
    : process.env.REACT_APP_MODE === 'production'
    ? `${process.env.REACT_APP_PROD_SERVER}avatars/`
    : `${process.env.REACT_APP_TES_SERVER}avatars/`

const thumbBase =
  process.env.REACT_APP_MODE === 'development'
    ? `${process.env.REACT_APP_TES_SERVER}thumbs/`
    : process.env.REACT_APP_MODE === 'production'
    ? `${process.env.REACT_APP_PROD_SERVER}thumbs/`
    : `${process.env.REACT_APP_TES_SERVER}thumbs/`

const fileBase =
  process.env.REACT_APP_MODE === 'development'
    ? `${process.env.REACT_APP_TES_SERVER}files/`
    : process.env.REACT_APP_MODE === 'production'
    ? `${process.env.REACT_APP_PROD_SERVER}files/`
    : `${process.env.REACT_APP_TES_SERVER}files/`

const attachmentBase =
  process.env.REACT_APP_MODE === 'development'
    ? `${process.env.REACT_APP_TES_SERVER}attachment/`
    : process.env.REACT_APP_MODE === 'production'
    ? `${process.env.REACT_APP_PROD_SERVER}attachment/`
    : `${process.env.REACT_APP_TES_SERVER}attachment/`

const cvfilesBase =
  process.env.REACT_APP_MODE === 'development'
    ? `${process.env.REACT_APP_TES_SERVER}cvfiles/`
    : process.env.REACT_APP_MODE === 'production'
    ? `${process.env.REACT_APP_PROD_SERVER}cvfiles/`
    : `${process.env.REACT_APP_TES_SERVER}cvfiles/`

const socketBase =
  process.env.REACT_APP_MODE === 'development'
    ? `${process.env.REACT_APP_TES_SERVER}`
    : process.env.REACT_APP_MODE === 'production'
    ? `${process.env.REACT_APP_PROD_SERVER}`
    : `${process.env.REACT_APP_TES_SERVER}`

export {
  serverBase,
  assetBase,
  thumbBase,
  fileBase,
  attachmentBase,
  cvfilesBase,
  socketBase
}
