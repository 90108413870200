import React from "react";
import { Navbar } from "reactstrap";
import { connect, useSelector } from "react-redux";
import classnames from "classnames";
import { useAuth0 } from "../../../authServices/auth0/auth0Service";

import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import logo from "../../../assets/img/logo/bonyan-logo-only.png";
import logoDark from "../../../assets/img/logo/bonyan-logo-only.png";
import { FormattedMessage, injectIntl } from "react-intl";


const UserName = (props) => {
  let username = "";
  if (props.userdata !== undefined) {
    username = props.userdata.name;
  } else if (props.user.login.values !== undefined) {
    username = props.user.login.values.name;
    if (
      props.user.login.values.loggedInWith !== undefined &&
      props.user.login.values.loggedInWith === "jwt"
    ) {
      username = props.user.login.values.loggedInUser.name;
    }
  } else {
    username = "Johngf Doe";
  }

  return username;
};
const ThemeNavbar = (props) => {
  const { user } = ""; /*useAuth0()*/
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark", "secondary"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const app = useSelector((state) => state.customizer.customizer);
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ,
            "navbar-dark": colorsArr.includes(props.navbarColor),
              "bg-primary": props.navbarColor === "primary" && props.navbarType !== "hidden",
              "bg-danger": props.navbarColor === "danger" && props.navbarType !== "hidden",
              "bg-success": props.navbarColor === "success" && props.navbarType !== "hidden",
              "bg-info": props.navbarColor === "info" && props.navbarType !== "hidden",
              "bg-warning": props.navbarColor === "warning" && props.navbarType !== "hidden",
              "bg-dark": props.navbarColor === "dark" && props.navbarType !== "hidden",
              "bg-secondary": props.navbarColor === "secondary" && props.navbarType !== "hidden",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
              "navbar-static-top": props.navbarType === "hidden" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content ">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                  <div className="d-flex align-items-center">
                  <h2
                    className={`${app.theme === "dark" ? app.navbarColor === "info" || app.navbarColor === "warning" || app.navbarColor === "secondary" ? "ytextc" : "white" : app.navbarColor === "default" || app.navbarColor === "info" || app.navbarColor === "warning" || app.navbarColor === "secondary" ? "ytextc" : "white"} font-kafaa-130`}>
                 <FormattedMessage id={"Kafaa"} />
                 </h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName userdata={user} {...props} />}
                userImg={
                  props.user.login.values !== undefined &&
                  props.user.login.values.loggedInWith !== "jwt" &&
                  props.user.login.values.photoUrl
                    ? props.user.login.values.photoUrl
                    : user !== undefined && user.picture
                }
                loggedInWith={
                  props.user !== undefined && props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
                logoutWithFirebase={props.logoutWithFirebase}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

export default connect(mapStateToProps, {
  useAuth0,
})(ThemeNavbar);
