import React from "react";
import { CustomInput } from "reactstrap";
import { Settings, X } from "react-feather";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ContextLayout } from "../../../utility/context/Layout";
import Radio from "../radio/RadioVuexy";
import "../../../assets/scss/components/customizer.scss";
import themeConfig from "../../../configs/themeConfig.js";
import { getThemes, getUser, updateSessionTheme } from "../../../utility/Common";
import { serverBase } from "../../../utility/axios";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

class Customizer extends React.Component {
  state = {
    activeNavbar: this.props.activeNavbar,
    navbarType: null,
    footerType: null,
    menuTheme: null,
    collapsedSidebar: null,
    user: getUser(),
    chek: null,
    isChecked: themeConfig.hideScrollToTop,
    hideBuyButton: themeConfig.hideBuyButton,
    isCheckedDirection: themeConfig.direction,
  };

  handleSwitchChange = (x) => {
    this.setState({
      isChecked: x,
    });
    themeConfig.hideScrollToTop = x === false ? "False" : "True";
  };

    handleSwitchbuy = (user, valuecheck, footer, companent) => {
        if (companent === "fromfooter") {
            this.setState({
                hideBuyButton: valuecheck,
            });
            themeConfig.hideBuyButton = valuecheck;
            this.props.hideBuyButton(valuecheck === true ? false : true)
            this.updateThemeToDatabase(user, "hideBuyButton", valuecheck);
            document.getElementById("hideBuyButton").checked = false
        }
        if (footer === "sticky") {
            if (valuecheck) {                
                this.props.changeFooterType("static");
                this.updateThemeToDatabase(user._id, "footerType", "static");
            }
    }
    this.setState({
        hideBuyButton: valuecheck,
    });
        themeConfig.hideBuyButton = valuecheck;
        this.props.hideBuyButton(valuecheck === true ? false : true)
        this.updateThemeToDatabase(user, "hideBuyButton", valuecheck);
  };




  handleSwitchChangeDirection = () => {
    this.setState({
      isCheckedDirection: this.state.isCheckedDirection === "rtl" ? "ltr" : "rtl",
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.navbarType !== state.navbarType ||
      props.footerType !== state.footerType ||
      props.menuTheme !== state.menuTheme ||
      props.activeMode !== state.activeMode ||
      props.sidebarState !== state.collapsedSidebar ||
      props.hideBuyButton !== state.hideBuyButton
    ) {
      return {
        activeNavbar: props.activeNavbar,
        navbarType: props.navbarType,
        footerType: props.footerType,
        menuTheme: props.menuTheme,
        activeMode: props.activeMode,
        collapsedSidebar: props.sidebarState,
        hideBuyButton: props.hideBuyButton,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  handleNavbarChange = (color) => {
    this.props.changeNavbar(color);
    this.setState({
      activeNavbar: color,
    });
  };

  updateThemeToDatabase = async (_id, type, value) => {
    await serverBase.post("theme/update", {
      _id,
      type,
      value,
    });
    updateSessionTheme(type, value);
  };

  componentDidMount() {
    this.setState({
      navbarType: this.props.navbarType,
      footerType: this.props.footerType,
      menuTheme: this.props.menuTheme,
      activeMode: this.props.activeMode,
      collapseSidebar: this.props.sidebarState,      
      hideBuyButton: this.props.hideBuyButton, 
    });
  }

  render() {
      const { activeNavbar, navbarType, footerType, menuTheme, activeMode, collapsedSidebar, user, hideBuyButton } =
      this.state;
      const { intl } = this.props;
    return (
      <ContextLayout.Consumer>
        {(context) => {
          return (
            <div
              className={classnames("customizer d-none d-md-block", {
                open: this.props.customizerState === true,
              })}
            >
              <div
                className="customizer-toggle"
                onClick={() => this.props.handleCustomizer(!this.props.customizerState)}
              >
                <Settings className="open-icon" size={15} />
              </div>
              <div className="header d-flex justify-content-between px-2 pt-2">
                <div className="title">
                  <h4 className="text-uppercase mb-0 font-kafaa-1">
                    <FormattedMessage id={"Theme Customizer"} />
                  </h4>
                  <small>
                    <FormattedMessage id={"Customized & Preview in Real Time"} />
                  </small>
                </div>
                <div
                  className="close-icon cursor-pointer"
                  onClick={() => this.props.handleCustomizer(false)}
                >
                  <X size={24} />
                </div>
              </div>
              <hr />
              <PerfectScrollbar
                options={{
                  wheelPropagation: false,
                }}
                className="customizer-content p-2"
              >
                <div className="menu-theme-colors">
                  <h5 className="font-kafaa-9">
                    <FormattedMessage id={"Menu Colors"} />
                  </h5>
                  <ul className="list-inline unstyled-list">
                    <li
                      className={classnames("color-box bg-primary", {
                        selected:
                          menuTheme === "primary" ||
                              !["primary", "danger", "info", "warning", "dark", "success", "secondary"].includes(
                            menuTheme
                          ),
                      })}
                      onClick={() => {
                        this.props.changeMenuTheme("primary");
                        this.updateThemeToDatabase(user._id, "menuTheme", "primary");
                      }}
                    />
                    <li
                      className={classnames("color-box bg-success", {
                        selected: menuTheme === "success",
                      })}
                      onClick={() => {
                        this.props.changeMenuTheme("success");
                        this.updateThemeToDatabase(user._id, "menuTheme", "success");
                      }}
                    />
                    <li
                      className={classnames("color-box bg-danger", {
                        selected: menuTheme === "danger",
                      })}
                      onClick={() => {
                        this.props.changeMenuTheme("danger");
                        this.updateThemeToDatabase(user._id, "menuTheme", "danger");
                      }}
                    />
                    <li
                      className={classnames("color-box bg-info", {
                        selected: menuTheme === "info",
                      })}
                      onClick={() => {
                        this.props.changeMenuTheme("info");
                        this.updateThemeToDatabase(user._id, "menuTheme", "info");
                      }}
                    />
                    <li
                      className={classnames("color-box bg-warning", {
                        selected: menuTheme === "warning",
                      })}
                      onClick={() => {
                        this.props.changeMenuTheme("warning");
                        this.updateThemeToDatabase(user._id, "menuTheme", "warning");
                      }}
                    />
                    {activeMode !== "semi-dark" && (
                    <li
                      className={classnames("color-box", activeMode === "dark" ? "bg-light" : "bg-secondary" , {
                        selected: menuTheme === "secondary",
                      })}
                      onClick={() => {
                          this.props.changeMenuTheme("secondary");
                        this.updateThemeToDatabase(user._id, "menuTheme", "secondary");
                      }}
                    />
                    )}
                  </ul>
                </div>
                <hr />
                <div className="theme-layout">
                  <h5 className="mt-1 font-kafaa-9">
                    <FormattedMessage id={"Theme Layout"} />
                  </h5>
                  <div className="d-inline-block mr-1">
                    <Radio
                      label={intl.messages["customizer.layout.vertical.label"]}
                      color="primary"
                      checked={
                        context.state.activeLayout === "vertical" ||
                        !["vertical", "horizontal"].includes(context.state.activeLayout)
                      }
                      name="themeMode"
                      onChange={() => {
                        context.switchLayout("vertical");
                        this.updateThemeToDatabase(user._id, "layout", "vertical");
                      }}
                    />
                  </div>
                  <div className="d-inline-block">
                    <Radio
                      label={intl.messages["customizer.layout.horizontal.label"]}
                      color="primary"
                      checked={context.state.activeLayout === "horizontal"}
                      name="themeMode"
                      onChange={() => {
                        context.switchLayout("horizontal");
                        this.updateThemeToDatabase(user._id, "layout", "horizontal");
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="theme-mode">
                  <h5 className="mt-1 font-kafaa-9">
                    <FormattedMessage id={"Themes Mode"} />
                  </h5>
                  <div className="d-inline-block mr-1">
                    <Radio
                      label={<FormattedMessage id={"Light"} />}
                      color="primary"
                      checked={activeMode === "light" ? true : false}
                      name="themeLayout"
                      onChange={() => {
                        this.props.changeMode("light");
                        this.updateThemeToDatabase(user._id, "theme", "light");
                      }}
                    />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      label={<FormattedMessage id={"Dark"} />}
                      color="primary"
                      checked={activeMode === "dark" ? true : false}
                      name="themeLayout"
                      onChange={() => {
                        this.props.changeMode("dark");
                        this.updateThemeToDatabase(user._id, "theme", "dark");
                      }}
                    />
                  </div>
                  {/* {context.state.activeLayout !== 'horizontal' && ( */}
                  <div className="d-inline-block">
                    <Radio
                      label={<FormattedMessage id={"Semi Dark"} />}
                      color="primary"
                      checked={activeMode === "semi-dark" ? true : false}
                      name="themeLayout"
                      onChange={() => {
                          this.handleNavbarChange("default");
                          this.updateThemeToDatabase(user._id, "navbarColor", "default");                          
                          if (menuTheme === "secondary") { 
                              this.props.changeMenuTheme("primary");
                              this.updateThemeToDatabase(user._id, "menuTheme", "primary")
                          }
                          this.props.changeMode("semi-dark");
                          this.updateThemeToDatabase(user._id, "theme", "semi-dark");
                      }}
                    />
                  </div>
                  {/* )} */}
                </div>
                {context.state.activeLayout !== "horizontal" && (
                  <React.Fragment>
                    <hr />
                    <div className="collapse-sidebar d-flex justify-content-between align-items-center">
                      <h5 className="pt-25 font-kafaa-9">
                        <FormattedMessage id={"Collapse Sidebar"} />
                      </h5>
                      <CustomInput
                        type="switch"
                        id="collapseSidebar"
                        name="collapseSidebar"
                        checked={collapsedSidebar}
                        onChange={() => {
                          this.props.collapseSidebar(!collapsedSidebar);
                          this.updateThemeToDatabase(
                            user._id,
                            "sidebarCollapsed",
                            !collapsedSidebar
                          );
                        }}
                        inline
                      />
                    </div>
                  </React.Fragment>
                )}
                <hr />
                {navbarType !== "hidden" && (
                  <React.Fragment>
                    <div className="navbar-colors ">
                      <h5 className="font-kafaa-9">
                        <FormattedMessage id={"Navbar Colors"} />
                      </h5>
                      <ul className="list-inline unstyled-list">
                        <li
                          className={classnames("color-box bg-default border", {
                            selected:
                              activeNavbar === "default" ||
                                  navbarType === "hidden" ||
                              ![
                                "default",
                                "primary",
                                "danger",
                                "info",
                                "warning",
                                "dark",
                                "success",
                                "secondary",
                              ].includes(activeNavbar),
                              disabled: navbarType === "hidden",
                          })}
                          onClick={() => {
                            this.handleNavbarChange("default");
                            this.updateThemeToDatabase(user._id, "navbarColor", "default");
                          }}
                        />
                        <li
                          className={classnames("color-box bg-primary", {
                              selected: activeNavbar === "primary" && navbarType !== "hidden",
                              disabled: navbarType === "hidden",
                          })}
                          onClick={() => {
                            this.handleNavbarChange("primary");
                            this.updateThemeToDatabase(user._id, "navbarColor", "primary");
                          }}
                        />
                        <li
                          className={classnames("color-box bg-success", {
                            selected:
                                  activeNavbar === "success" && this.props.navbarType !== "hidden",
                              disabled: navbarType === "hidden",
                          })}
                          onClick={() => {
                            this.handleNavbarChange("success");
                            this.updateThemeToDatabase(user._id, "navbarColor", "success");
                          }}
                        />
                        <li
                          className={classnames("color-box bg-danger", {
                            selected:
                                  activeNavbar === "danger" && this.props.navbarType !== "hidden",
                              disabled: navbarType === "hidden",
                          })}
                          onClick={() => {
                            this.handleNavbarChange("danger");
                            this.updateThemeToDatabase(user._id, "navbarColor", "danger");
                          }}
                        />
                        <li
                          className={classnames("color-box bg-info", {
                              selected: activeNavbar === "info" && this.props.navbarType !== "hidden",
                              disabled: navbarType === "hidden",
                          })}
                          onClick={() => {
                            this.handleNavbarChange("info");
                            this.updateThemeToDatabase(user._id, "navbarColor", "info");
                          }}
                        />
                        <li
                          className={classnames("color-box bg-warning", {
                            selected:
                                  activeNavbar === "warning" && this.props.navbarType !== "hidden",
                              disabled: navbarType === "hidden",
                          })}
                          onClick={() => {
                            this.handleNavbarChange("warning");
                            this.updateThemeToDatabase(user._id, "navbarColor", "warning");
                          }}
                        />
                          { activeMode !== "semi-dark" && (
                          <li 
                          className={classnames("color-box", activeMode === "dark" ? "bg-light" : "bg-secondary" , {
                              selected: activeNavbar === "secondary" && this.props.navbarType !== "hidden",
                              disabled: navbarType === "hidden",                            
                          })}
                            onClick={() => {                                             
                            this.handleNavbarChange("secondary");
                            this.updateThemeToDatabase(user._id, "navbarColor", "secondary");
                          }}
                        />
                          )}
                      </ul>
                    </div>
                    <hr />
                  </React.Fragment>
                )}
                <div className="navbar-type">
                  <h5 className="font-kafaa-9">
                    <FormattedMessage id={"Navbar Type"} />
                  </h5>
                  {context.state.activeLayout !== "horizontal" && (
                    <div className="d-inline-block mr-1">
                      <Radio
                        label={<FormattedMessage id="customizer.navbar_type.hidden.label" />}
                        color="primary"
                        checked={navbarType === "hidden" ? true : false}
                        name="navbarType"
                        onChange={() => {
                          this.props.changeNavbarType("hidden");
                          this.updateThemeToDatabase(user._id, "navbarType", "hidden");
                        }}
                      />
                    </div>
                  )}
                  <div className="d-inline-block mr-1">
                    <Radio
                      label={<FormattedMessage id="customizer.navbar_type.static.label" />}
                      color="primary"
                      checked={navbarType === "static" ? true : false}
                      name="navbarType"
                      onChange={() => {
                        this.props.changeNavbarType("static");
                        this.updateThemeToDatabase(user._id, "navbarType", "static");
                      }}
                    />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      label={<FormattedMessage id="customizer.navbar_type.sticky.label" />}
                      color="primary"
                      checked={navbarType === "sticky" ? true : false}
                      name="navbarType"
                      onChange={() => {
                        this.props.changeNavbarType("sticky");
                        this.updateThemeToDatabase(user._id, "navbarType", "sticky");
                      }}
                    />
                  </div>
                  <div className="d-inline-block">
                    <Radio
                      label={<FormattedMessage id="customizer.navbar_type.floating.label" />}
                      color="primary"
                      checked={
                        navbarType === "floating" ||
                        !["floating", "hidden", "static", "sticky"].includes(navbarType)
                          ? true
                          : false
                      }
                      name="navbarType"
                      onChange={() => {
                        this.props.changeNavbarType("floating");
                        this.updateThemeToDatabase(user._id, "navbarType", "floating");
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="footer-type">
                  <h5 className="font-kafaa-9">
                    <FormattedMessage id={"Footer Type"} />
                  </h5>
                  
                  <div className="d-inline-block mr-1">
                    <Radio
                      label={<FormattedMessage id="customizer.footer_type.static.label" />}
                      color="primary"
                      checked={footerType === "static" ? true : false}
                      name="footerType"
                      onChange={() => {
                        this.props.changeFooterType("static");                         
                        this.updateThemeToDatabase(user._id, "footerType", "static");
                      }}
                    />
                  </div>
                  <div className="d-inline-block">
                    <Radio
                      label={<FormattedMessage id="customizer.footer_type.sticky.label" />}
                      color="primary"
                      checked={footerType === "sticky" ? true : false}
                      name="footerType"
                      onChange={() => {                         
                          if (themeConfig.hideBuyButton) {
                              this.handleSwitchbuy(user._id, false, "", "fromfooter");                              
                          } 
                          this.props.changeFooterType("sticky");
                          this.updateThemeToDatabase(user._id, "footerType", "sticky");
                          
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="pt-25 font-kafaa-9">
                    <FormattedMessage id="customizer.page_orientation.label" />
                  </h5>
                  <CustomInput
                    type="switch"
                    id="rtl-version"
                    name="rtl-version"
                    inline
                    defaultChecked={themeConfig.direction === "ltr" ? false : true}
                    onChange={(e, chek) => {
                      this.handleSwitchChangeDirection();
                      if (this.state.isCheckedDirection === "rtl") {
                        themeConfig.direction = "ltr";
                        document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
                        chek = "ltr";
                      } else {
                        themeConfig.direction = "rtl";
                        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
                        chek = "rtl";
                      }
                      this.updateThemeToDatabase(user._id, "direction", chek);
                    }}
                  />
                </div>
                <hr />
                      {/* WORK */}  <div className="scroll-to-top d-flex justify-content-between align-items-center">
                          <h5 className="pt-25 font-kafaa-9">
                              <FormattedMessage id={"Hide Scroll To Top"} />
                          </h5>
                          <CustomInput
                              type="switch"
                              id="scrollToTop"
                              name="scrollToTop"
                              defaultChecked={themeConfig.hideScrollToTop === "True" ? true : false}
                              inline
                              onChange={(e) => {
                                  this.handleSwitchChange(e.target.checked);
                                  this.updateThemeToDatabase(
                                      user._id,
                                      "hideScrollToTop",
                                      e.target.checked === false ? "False" : "True"
                                  );
                              }}
                          />
                      </div>       <hr />               
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="pt-25 font-kafaa-9">
                        <FormattedMessage id="config.switch.datapage" />
                      </h5>
                      <CustomInput
                        type="switch"
                        id="hideBuyButton"
                        name="hideBuyButton"
                        defaultChecked={themeConfig.hideBuyButton }
                        inline
                        onClick={(e) => {                                 
                            this.handleSwitchbuy(user._id, e.target.checked, footerType, "");
                        }}
                      />
                    </div>
                            
              </PerfectScrollbar>
            </div>
          );
        }}
      </ContextLayout.Consumer>
    );
  }
}

export default connect()(injectIntl(Customizer));
