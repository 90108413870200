import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { history } from "../../history";
import { getThemes, getUser } from "../../utility/Common";
import themeConfig from "../../configs/themeConfig.js";
import "./Style.scss";

const BuyNow = () => {
  const customizer = useSelector((state) => state.customizer);
  return (
    <div className="buy-now">
      {themeConfig.hideBuyButton === true && (
        <div>
          <div>
            <Button
              className={`aligbotton ${
                customizer.customizer.menuTheme === "info" ||
                customizer.customizer.menuTheme === "warning" ||
                customizer.customizer.menuTheme === "secondary"
                  ? "ytextc"
                  : "white"
              } bg-btn-dash-${customizer.customizer.menuTheme} dis-none`}
              onClick={() => history.push("/money-request")}
            >
              <FormattedMessage id="Money Requestbotton" />
            </Button>
            <Button
              className={`aligbotton ${
                customizer.customizer.menuTheme === "info" ||
                customizer.customizer.menuTheme === "warning" ||
                customizer.customizer.menuTheme === "secondary"
                  ? "ytextc"
                  : "white"
              } bg-btn-dash-${customizer.customizer.menuTheme} dis-none`}
              onClick={() => history.push("/app/task/list")}
            >
              <FormattedMessage id="dashboard.quick_menu.go_to_task_list" />
            </Button>
            {getUser().role === "admin" || getUser().role === "accountant" ? (
              <Button
                className={`aligbotton ${
                  customizer.customizer.menuTheme === "info" ||
                  customizer.customizer.menuTheme === "warning" ||
                  customizer.customizer.menuTheme === "secondary"
                    ? "ytextc"
                    : "white"
                } bg-btn-dash-${customizer.customizer.menuTheme} dis-none`}
                onClick={() => history.push("/bookkeeping")}
              >
                <FormattedMessage id="Bookkeeping" />
              </Button>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyNow;
