import React from "react";
import moment from "moment";
import _ from "lodash";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import ReactCountryFlag from "react-country-flag";
import { FormattedMessage, useIntl } from "react-intl";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { ArabicNumbers } from "react-native-arabic-numbers";
// import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { useNotif } from "../../../contexts/notificationContext";
import { history } from "../../../history";
import { IntlContext } from "../../../utility/context/Internationalization";
import { getUser, removeUserSession, updateSessionTheme } from "../../../utility/Common";
import themeConfig from "../../../configs/themeConfig";
import { serverBase, assetBase } from "../../../utility/axios";
import { number } from "../../../utility/helper";

const base_url = "/";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};
const UserDropdown = (props) => {
  const { logout, isAuthenticated } = ""; /*useAuth0()*/
    return (
        <DropdownMenu>
      <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, "/email/inbox")}>
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id={"My Inbox"} />
        </span>
      </DropdownItem>
      <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, "/app/task/list")}>
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id={"Tasks"} />
        </span>
      </DropdownItem>
      <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, "/chat")}>
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id={"Chats"} />
        </span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/pages/account-settings")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id={"Account Settings"} />
        </span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/"
        onClick={(e) => {
          e.preventDefault();
          if (isAuthenticated) {
            return logout({
              returnTo: window.location.origin + process.env.REACT_APP_PUBLIC_PATH,
            });
          } else {
            removeUserSession();
            const provider = props.loggedInWith;
            if (provider !== null) {
              if (provider === "jwt") {
                return props.logoutWithJWT();
              }
              if (provider === "firebase") {
                return props.logoutWithFirebase();
              }
            } else {
              window.location.href = "/";
            }
          }
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id={"Log Out"} />
        </span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = ({ handleAppOverlay, loggedInWith, logoutWithJWT, logoutWithFirebase }) => {
  var { notifications } = useNotif();
  const { dumpAllNotification } = useNotif();
  const [user] = React.useState(getUser());
  const [navbarSearch, setNavbarSearch] = React.useState(false);
  const [langDropdown, setLangDropdown] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const [filteredNotif, setFilteredNotif] = React.useState({});
  const [notif, setNotif] = React.useState({
    task: [],
  });
  const intl = useIntl();

  React.useEffect(() => {
    axios.get("/api/main-search/data").then(({ data }) => {
      setSuggestions(data.searchResult);
    });
  }, []);

  React.useEffect(() => {
    if (notifications) {
      let newNotif = {};
      Object.keys(notifications).forEach((key) => {
        if (notifications[key].length > 0) {
          newNotif[key] = notifications[key];
        }
      });
      setFilteredNotif(newNotif);
      if (newNotif.task) {
        if (newNotif.task.length > 0) {
          let tasks = [];
          const taskNotif = newNotif["task"].filter((nt) => nt.isSeen === false);
          taskNotif.map((t) =>
            tasks.push({
              NotifIcon: <Icon.Grid className="font-medium-5 primary" size={21} />,
              headline: "Updated task",
              title: t.title,
              link: `${base_url}app/task/activity/${t._id}`,
              _id: t._id,
            })
          );
          setNotif({ task: tasks });
        } else {
          setNotif({ task: [] });
        }
      } else {
        setNotif({ task: [] });
      }
    }
  }, [notifications]);

  const handleNavbarSearch = () => setNavbarSearch(!navbarSearch);

  const handleLangDropdown = () => setLangDropdown(!langDropdown);

  const handleTaskClick = async (to, id) => {
    const user = {
      user_id: getUser()._id,
      user_role: getUser().role,
      task_id: id,
    };

    await serverBase.post("task/mark-read", user).then((res) => {
      if (res.status === 200) {
        const task_ = [...notif.task].filter((t) => t._id !== id);
        setNotif({ ...notif, task: task_ });
        setFilteredNotif((prev) => ({ ...prev, task: task_ }));
        history.push(to);
      }
    });
  };

  const hadleClearNotif = () => {
    const notifs = {
      ...filteredNotif,
    };
    const user_ = {
      _id: getUser()._id,
      role: getUser().role,
    };
    dumpAllNotification(notifs, user_);
    setNotif({ task: [] });
  };

  const updateThemeToDatabase = async (_id, type, value) => {
    await serverBase.post("theme/update", {
      _id,
      type,
      value,
    });
    updateSessionTheme(type, value);
  };

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <IntlContext.Consumer>
        {(context) => {
          let langArr = {
            en: "English",
            sa: "عربي",
            id: "Indonesia",
          };
          return (
            <Dropdown
              tag="li"
              className="dropdown-language nav-item"
              isOpen={langDropdown}
              toggle={handleLangDropdown}
              data-tour="language"
            >
              <DropdownToggle tag="a" className="nav-link">
                <ReactCountryFlag
                  className="country-flag"
                  countryCode={themeConfig.locale === "en" ? "us" : themeConfig.locale}
                  svg
                />
                <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                  {langArr[themeConfig.locale]}
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  tag="a"
                  onClick={async (e) => {
                    context.switchLanguage("sa");
                    themeConfig.locale = "sa";
                    themeConfig.direction = "rtl";
                    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
                    await updateThemeToDatabase(user._id, "direction", "rtl");
                    await updateThemeToDatabase(user._id, "locale", "sa");
                    e.stopPropagation();
                  }}
                >
                  <ReactCountryFlag className="country-flag" countryCode="sa" svg />
                  <span className="ml-1">العربية</span>
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  onClick={async (e) => {
                    context.switchLanguage("id");
                    themeConfig.locale = "id";
                    themeConfig.direction = "ltr";
                    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
                    await updateThemeToDatabase(user._id, "direction", "ltr");
                    await updateThemeToDatabase(user._id, "locale", "id");
                    e.stopPropagation();
                  }}
                >
                  <ReactCountryFlag className="country-flag" countryCode="id" svg />
                  <span className="ml-1">Indonesia</span>
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  onClick={async (e) => {
                    context.switchLanguage("en");
                    themeConfig.locale = "en";
                    themeConfig.direction = "ltr";
                    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
                    await updateThemeToDatabase(user._id, "direction", "ltr");
                    await updateThemeToDatabase(user._id, "locale", "en");
                    e.stopPropagation();
                  }}
                >
                  <ReactCountryFlag className="country-flag" countryCode="us" svg />
                  <span className="ml-1">English</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          );
        }}
      </IntlContext.Consumer>

      <NavItem className="nav-search" onClick={handleNavbarSearch}>
        <NavLink className="nav-link-search">
          <Icon.Search size={21} data-tour="search" />
        </NavLink>
        <div
          className={classnames("search-input", {
            open: navbarSearch,
            "d-none": navbarSearch === false,
          })}
        >
          <div className="search-input-icon">
            <Icon.Search size={17} className="primary" />
          </div>
          <Autocomplete
            className="form-control"
            suggestions={suggestions}
            filterKey="title"
            filterHeaderKey="groupTitle"
            grouped={true}
            placeholder={intl.messages["navbar.search.placeholder"]}
            autoFocus={true}
            clearInput={navbarSearch}
            externalClick={(e) => setNavbarSearch(false)}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 13) {
                setNavbarSearch(false);
                handleAppOverlay("");
              }
            }}
            customRender={(
              item,
              i,
              filteredData,
              activeSuggestion,
              onSuggestionItemClick,
              onSuggestionItemHover
            ) => {
              const IconTag = Icon[item.icon ? item.icon : "X"];

              return (
                <li
                  className={classnames("suggestion-item", {
                    active: filteredData.indexOf(item) === activeSuggestion,
                  })}
                  key={i}
                  onClick={(e) => onSuggestionItemClick(item.link, e)}
                  onMouseEnter={() => onSuggestionItemHover(filteredData.indexOf(item))}
                >
                  <div
                    className={classnames({
                      "d-flex justify-content-between align-items-center": item.file || item.img,
                    })}
                  >
                    <div className="item-container d-flex">
                      {item.icon ? (
                        <IconTag size={17} />
                      ) : item.file ? (
                        <img src={require(item.file)} height="36" width="28" alt={item.title} />
                      ) : item?.img ? (
                        <img
                          className="rounded-circle mt-25"
                          src={require(item.img)}
                          height="28"
                          width="28"
                          alt={item.title}
                        />
                      ) : null}
                      <div className="item-info ml-1">
                        <p className="align-middle mb-0">{item.title}</p>
                        {item.by || item.email ? (
                          <small className="text-muted">
                            {item.by ? item.by : item.email ? item.email : null}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    {item.size || item.date ? (
                      <div className="meta-container">
                        <small className="text-muted">
                          {item.size ? item.size : item.date ? item.date : null}
                        </small>
                      </div>
                    ) : null}
                  </div>
                </li>
              );
            }}
            onSuggestionsShown={(userInput) => {
              if (navbarSearch) {
                handleAppOverlay(userInput);
              }
            }}
          />
          <div className="search-input-close">
            <Icon.X
              size={24}
              onClick={(e) => {
                e.stopPropagation();
                setNavbarSearch(false);
                handleAppOverlay("");
              }}
            />
          </div>
        </div>
      </NavItem>

      <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Icon.Bell size={21} />
          {Object.keys(filteredNotif).length +
            notif.task.length -
            (notifications.task.length === 0 ? 0 : 1) >
          0 ? (
            <Badge pill color="dark-danger" className="badge-up">
              {` ${
                Object.keys(filteredNotif).length +
                notif.task.length -
                (notifications.task.length === 0 ? 0 : 1)
              } `}
            </Badge>
          ) : null}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <h3 className="text-white font-kafaa-6">
                {number(
                  Object.keys(filteredNotif).length +
                    notif.task.length -
                    (notifications.task.length === 0 ? 0 : 1)
                )}
              </h3>
              <span className="notification-title">
                <FormattedMessage id="New Notification" />
              </span>
            </div>
          </li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false,
            }}
          >
            {Object.keys(filteredNotif).map((key) => {
              let NotifIcon = null;
              let headline = null;
              let link = "";
              let newest = _.orderBy(filteredNotif[key], ["date"], ["asc"]);
              let time =
                newest &&
                newest[0] &&
                newest[0].date &&
                moment.duration(moment(newest[0].date).diff(moment())).humanize(true);
              switch (key) {
                case "chat":
                  NotifIcon = <Icon.MessageSquare className="font-medium-5 primary" size={21} />;
                  headline = `${
                    notifications[key].length > 0
                      ? themeConfig.locale === "sa"
                        ? ArabicNumbers(notifications[key].length)
                        : notifications[key].length
                      : notifications[key].length
                  } رسالة جديدة `;
                  link = `${base_url}chat`;
                  break;
                case "email":
                  NotifIcon = <Icon.Mail className="font-medium-5 primary" size={21} />;
                  headline = `${
                    notifications[key].length > 0
                      ? themeConfig.locale === "sa"
                        ? ArabicNumbers(notifications[key].length)
                        : notifications[key].length
                      : notifications[key].length
                  } بريد جديدة `;
                  link = `${base_url}email/inbox`;
                  break;
                case "quickTask":
                  NotifIcon = <Icon.Zap className="font-medium-5 primary" size={21} />;
                  headline = `${
                    notifications[key].length > 0
                      ? themeConfig.locale === "sa"
                        ? ArabicNumbers(notifications[key].length)
                        : notifications[key].length
                      : notifications[key].length
                  } مهمة جديدة `;
                  link = `${base_url}todo/all`;
                  break;
                case "task":
                  NotifIcon = <Icon.Grid className="font-medium-5 primary" size={21} />;
                  headline = `${
                    notifications[key].length > 0
                      ? themeConfig.locale === "sa"
                        ? ArabicNumbers(notifications[key].length)
                        : notifications[key].length
                      : notifications[key].length
                  } معاملة جديدة `;
                  link = `${base_url}app/task/list`;
                  break;
                case "monreq":
                  NotifIcon = <Icon.BookOpen className="font-medium-5 primary" size={21} />;
                  headline = `${
                    notifications[key].length > 0
                      ? themeConfig.locale === "sa"
                        ? ArabicNumbers(notifications[key].length)
                        : notifications[key].length
                      : notifications[key].length
                  } معاملة جديدة `;
                  link = `${base_url}money-request`;
                  break;
                default:
                  NotifIcon = <Icon.AlertTriangle className="font-medium-5 danger" size={21} />;
                  break;
              }
              if (key !== "task") {
                return (
                  <div
                    className="d-flex justify-content-between"
                    key={`notif-${key}`}
                    onClick={() => history.push(link)}
                  >
                    <Media className="d-flex align-items-start">
                      <Media left href="#">
                        {NotifIcon}
                      </Media>
                      <Media body>
                        <Media heading className="primary media-heading" tag="h6">
                          <FormattedMessage id={key.charAt(0).toUpperCase() + key.slice(1)} />
                        </Media>
                        <p className="notification-text">{headline}</p>
                      </Media>
                      <small>
                        <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00">
                          {time}
                        </time>
                      </small>
                    </Media>
                  </div>
                );
              }
              return null;
            })}
            {/* NOTIF TASK */}
            {notif.task.length > 0 &&
              notif.task.map((nt, ntid) => {
                return (
                  <div
                    className="d-flex justify-content-between"
                    key={`notif-task-${ntid}`}
                    onClick={() => handleTaskClick(nt.link, nt._id)}
                  >
                    <Media className="d-flex align-items-start">
                      <Media left href="#">
                        {nt.NotifIcon}
                      </Media>
                      <Media body>
                        <Media heading className="primary media-heading" tag="h6">
                          <FormattedMessage id={"notification.task.title"} />
                        </Media>
                        <p className="notification-text">
                          <FormattedMessage
                            id={"notification.task.subtitle"}
                            values={{ title: nt.title }}
                          />
                        </p>
                      </Media>
                      <small>
                        <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00">
                          {/* {time} */}
                        </time>
                      </small>
                    </Media>
                  </div>
                );
              })}
          </PerfectScrollbar>
          <li className="dropdown-menu-footer">
            <DropdownItem tag="a" className="p-1 text-center">
              <span className="align-middle font-kafaa-6" onClick={() => hadleClearNotif()}>
                <FormattedMessage id="Hide All Notification" />
              </span>
            </DropdownItem>
          </li>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item ">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name font-kafaa-6">{user.name}</span>
            <span className="font-kafaa-aljazeera-10">
              <span className="font-kafaa-aljazeera-1">
                <FormattedMessage id={`user_add.option.user_role.${user.role}`} />
              </span>
              {user.connection && ` : ${user.connection.name}`}
            </span>
          </div>
          {/* -AVATAR */}
          <span data-tour="user">
            <img
              // preventive image doesn't update because of the browser cache
              src={`${assetBase}${user.avatar}?${global.Date.now()}`}
              className="round"
              height="40"
              width="40"
              alt="avatar"
            />
          </span>
        </DropdownToggle>
        <UserDropdown
          handleAppOverlay={handleAppOverlay}
          loggedInWith={loggedInWith}
          logoutWithJWT={logoutWithJWT}
          logoutWithFirebase={logoutWithFirebase}
        />
      </UncontrolledDropdown>
    </ul>
  );
};
export default NavbarUser;
