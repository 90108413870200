import themeConfig from '../../configs/themeConfig'
import mock from '../mock'

export const searchResult = [
  {
    groupTitle: themeConfig.locale === 'sa' ? 'صفحات النظام' : 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'dashboard',
        title: 'Dashboard',
        link: '/',
        icon: 'Home'
      },
      {
        id: 2,
        target: 'task',
        title: 'Task',
        link: '/app/task/list',
        icon: 'Grid'
      },
      {
        id: 3,
        target: 'todo',
        title: 'Todo',
        link: '/todo',
        icon: 'Zap'
      },
      {
        id: 4,
        target: 'manualReport',
        title: 'Manual Report',
        link: '/manual-report',
        icon: 'Clipboard'
      },
      {
        id: 5,
        target: 'Chat',
        title: 'Chat',
        link: '/chat',
        icon: 'MessageSquare'
      },
      {
        id: 6,
        target: 'Email',
        title: 'Email',
        link: '/email/inbox',
        icon: 'Mail'
      },
      {
        id: 7,
        target: 'Calender',
        title: 'Calender',
        link: '/calendar',
        icon: 'Calendar'
      },
      // app/user/user-view
      {
        id: 8,
        target: 'template',
        title: 'Template',
        link: '/app/template',
        icon: 'FileText'
      },
      {
        id: 9,
        target: 'myProfile',
        title: 'My Profile',
        link: '/app/user/user-view',
        icon: 'User'
      },
      {
        id: 10,
        target: 'editProfile',
        title: 'Edit Profile',
        link: '/pages/account-settings',
        icon: 'User'
      },
      {
        id: 11,
        target: 'information',
        title: 'Manage Information Page',
        link: '/home/information',
        icon: 'AlertCircle'
      }
      // /home/information
    ]
  }
  // {
  //   groupTitle: "الملفات المحفوظة",
  //   searchLimit: 4,
  //   data: [
  //   ]
  // },
  // {
  //   groupTitle: "الموظفون",
  //   searchLimit: 4,
  //   data: [
  //   ]
  // }
]

mock.onGet('/api/main-search/data').reply(200, {
  searchResult
})
