import React, { createContext, useReducer, useMemo, useContext } from "react";
import { serverBase } from "../utility/axios";
import { getUser } from "../utility/Common";
import { isFileOverLimit, user_usage_max } from "../utility/helper";

const ChatContext = createContext();
const initialChatState = [];

const ChatReducer = (state, action) => {
  switch (action.type) {
    case "SET_CHAT":
      return action.payload;
    case "UNSET_CHAT":
      return initialChatState;
    case "CHAT_MARK_READ":
      let copyState = [...state];
      const chatIdx = copyState.findIndex((chat) => chat.contact === action.payload.user_id);
      if (chatIdx > -1) {
        copyState[chatIdx].contents = copyState[chatIdx].contents.map((content) => ({
          ...content,
          isSeen: true,
        }));
      }
      return copyState;
    case "CHAT_NEW":
      let newState = [...state];
      const chatIndex = newState.findIndex((chat) => chat.contact === action.payload?.to);
      if (chatIndex > -1) {
        newState[chatIndex].contents = [...newState[chatIndex].contents, action.payload];
      } else {
        newState.push({
          contact: action.payload?.to,
          contents: [action.payload],
        });
      }

      return newState;
    default:
      return initialChatState;
  }
};

export const ChatProvider = (props) => {
  const [state, dispatch] = useReducer(ChatReducer, initialChatState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <ChatContext.Provider value={value} {...props} />;
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error(`useChat must be used within a ChatProvider`);
  }
  const [state, dispatch] = context;

  const getAllChat = async (_id) => {
    const res = await serverBase.get(`chat/all?_id=${_id}`);
    if (res.status === 200 && res.data) {
      dispatch({ type: "SET_CHAT", payload: res.data });
    }
  };

  const dumpAllChat = () => {
    dispatch({ type: "UNSET_CHAT" });
  };

  const markAllRead = async (payload) => {
    const res = await serverBase.post("chat/mark-read", { ...payload }).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
    if (res?.status === 200) {
      dispatch({ type: "CHAT_MARK_READ", payload });
    }
  };

  const newChat = async (payload) => {
    if (payload.files.length > 0) {
      const user = getUser();
      let formData = new FormData();
      formData.append("_id", user._id);
      formData.append("role", user.role);
      let totalSize = 0;
      Array.from(payload.files).forEach((file) => {
        totalSize = totalSize + file.file.size;
        formData.append("attach", file.file);
      });
      const file = { size: totalSize };
      const { user_usage, maximum_storage } = await user_usage_max(user._id);
      if (!isFileOverLimit(file, user_usage, maximum_storage)) {
        const uploadRes = await serverBase
          .post("file/upload-files", formData, {
            "Content-Type": "multipart/form-data",
          })
          .catch((err) => {
            if (err.response) {
              alert(err.response.data);
            }
          });

        const res = await serverBase
          .post("chat/new", {
            ...payload,
            file: payload.files.length > 0 && uploadRes.data[0]._id,
          })
          .catch((err) => {
            if (err.response) {
              alert(err.response.data);
            }
          });
        dispatch({ type: "CHAT_NEW", payload: res.data });
      }
    } else {
      const res = await serverBase.post("chat/new", { ...payload }).catch((err) => {
        if (err.response) {
          alert(err.response.data);
        }
      });
      dispatch({ type: "CHAT_NEW", payload: res?.data });
    }
  };

  return {
    chats: state,
    getAllChat,
    dumpAllChat,
    markAllRead,
    newChat,
  };
};
