import React, { createContext, useReducer, useMemo, useContext } from "react";
import { serverBase } from "../utility/axios";
import { getUser } from "../utility/Common";

const TaskContext = createContext();
const initialTaskState = [];

const TaskReducer = (state, action) => {
  switch (action.type) {
    case "SET_TASK":
      return action.payload;
    case "TASK_READ":
      return state.map((s) => ({ ...s, isSeen: true }));
    case "UNSET_TASK":
      return initialTaskState;
    default:
      return initialTaskState;
  }
};

export const TaskProvider = (props) => {
  const [state, dispatch] = useReducer(TaskReducer, initialTaskState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <TaskContext.Provider value={value} {...props} />;
};

export const useTask = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error(`useTask must be used within a TaskProvider`);
  }
  const [state, dispatch] = context;

  const getAllTask = async (_id) => {
    const user = getUser();
    await serverBase(`task/list/${user._id}`).then((res) => {
      const data =
        getUser().role === "admin" ? res.data : res.data.filter((d) => d.is_deleted === false);
      if (res.status === 200) {
        dispatch({ type: "SET_TASK", payload: data });
      }
    });
  };

  const markReadTask = async (data) => {
    // const res = await serverBase.post(`task/mark-read`, data).catch(err => {
    const res = await serverBase.post(`task/bulk-mark-read`, data).catch((err) => {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
      }
    });
    if (res && res.data === "success") {
      dispatch({ type: "TASK_READ" });
    }
    return res;
  };

  const dumpAllTask = () => {
    dispatch({ type: "UNSET_TASK" });
  };

  return {
    tasks: state,
    getAllTask,
    dumpAllTask,
    markReadTask,
  };
};
