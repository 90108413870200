// You can customize the theme with the help of this file
import { getThemes, getTheme } from "../utility/Common";
//Template config options
const user = getThemes() ? getThemes() : getTheme();

const themeConfig = {
  layout: user.layout, // options[String]: "vertical"(default), "horizontal"
  theme: user.theme, // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: user.sidebarCollapsed, // options[Boolean]: true, false(default)
  navbarColor: user.navbarColor, // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: user.navbarType, // options[String]: floating(default) / static / sticky / hidden
  footerType: user.footerType, // options[String]: static(default) / sticky / hidden
  disableCustomizer: user.disableCustomizer, // options[Boolean]: true, false(default)
  hideScrollToTop: user.hideScrollToTop, // options[Boolean]: true, false(default)
  hideBuyButton: user.hideBuyButton, // options[Boolean]: true, false(default)
  menuTheme: user.menuTheme, // options[String]: primary / success / danger / info / warning / dark
  direction: user.direction, // options[String] : ltr / rtl
  locale: user.locale, // options[String] : ltr / rtl
  showEditPageBtn: user.showEditPageBtn, //
};

export default themeConfig;
