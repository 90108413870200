import jsPDF from "jspdf";
import { ArabicNumbers } from "react-native-arabic-numbers";
import SimpleAlert from "../components/@kafaa/Alert/SimpleAlert";
import themeConfig from "../configs/themeConfig";
import { history } from "../history";
import { serverBase } from "./axios";

export const number = (num) => {
  if (themeConfig.locale === "sa") {
    return ArabicNumbers(num);
  } else {
    return num;
  }
};

export const numbercurrency = (cur) => {
  if (themeConfig.locale === "sa") {
    if (cur === "SAR") return "ريال";
    if (cur === "IDR") return "روبية";
    if (cur === "USD") return "دولار";
  } else {
    return cur;
  }
};

export const formatComma = (number, locale = themeConfig.locale) => {
  if (locale === "sa") {
    return number.toLocaleString("ar-SA").replace(/٬/gi, ",");
  } else {
    return number.toLocaleString("en-US");
  }
};

export const numbercurrencyreprt = (cur) => {
  if (themeConfig.locale === "sa") {
    if (cur === "SAR") return "الريال";
    if (cur === "IDR") return "الروبية";
    if (cur === "USD") return "الدولار";
  } else {
    return cur;
  }
};

export const intNumber = (num) => {
  if (themeConfig.locale === "sa") return Intl.NumberFormat("ar-SA").format(num);
  return Intl.NumberFormat("en-US").format(num);
};

export const currency = (number, prefix) => {
  if (themeConfig.locale === "sa") {
    return `${prefix} ${Intl.NumberFormat("ar-SA").format(number)}`;
  } else {
    return `${prefix} ${Intl.NumberFormat("en-US").format(number)}`;
  }
};

export const percentNumber = (num) => {
  if (themeConfig.locale === "sa") {
    // return ['٪',ArabicNumbers(num)].join('')
    const percent = "٪";
    return `${percent}${ArabicNumbers(num)}`;
  } else {
    return `${num}٪`;
  }
};

export const textNumber = (config) => {
  if (themeConfig.locale === "sa" && config.value) {
    if (config.saText[0] === "end") {
      return `${ArabicNumbers(config.value)}${config.saText[1]}`;
    } else if (config.saText[0] === "start") {
      return `${config.saText[1]}${ArabicNumbers(config.value)}`;
    }
  } else {
    if (config.enText[0] === "start") {
      return `${config.enText[1]}${config.value}`;
    } else if (config.enText[0] === "end") {
      return `${config.value}${config.enText[1]}`;
    }
  }
};

export const user_usage_max = async (_id) => {
  let res = await serverBase.get(`file/list?_id=${_id}`);
  if (res.status === 200 && res.data) {
    return res.data;
  } else {
    return { files: [], user_usage: 0 };
  }
};

export const isFileOverLimit = (file, usage, max) => {
  const total = file.size + usage;
  if (total > max) {
    SimpleAlert({
      intlMessage: "file_manager.alert.maximum_storage",
    });
    return true;
  } else {
    return false;
  }
};

export const exportPdfTask = () => {
  var doc = new jsPDF();
  var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  // doc title
  doc.setFontSize(28);
  doc.text(20, 25, `عرض المعاملة`);

  // sub title (Room Name)
  doc.setFontSize(14);
  doc.text(20, 35, `Room Name: Names`);

  // meta data (checkin ID / start / end)
  doc.setTextColor(150);
  doc.setFontSize(10);
  doc.text(140, 22, "ID:");
  doc.text(155, 22, "#1231412");
  doc.text(140, 28, "From:");
  doc.text(155, 28, "startTime");
  doc.text(140, 34, "To:");
  doc.text(155, 34, "endTime");

  doc.line(10, 45, 200, 45); // horizontal line

  // checkin data
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(16);
  doc.text(20, 60, `Total Attendence: 20 (30%)`);

  // list names
  doc.setFontSize(12);
  doc.setTextColor(150);
  doc.text(20, 80, "Name");
  doc.text(90, 80, "Attended");
  doc.text(150, 80, "Checkin Time");

  doc.setDrawColor(128, 128, 128); // draw red lines
  doc.line(10, 85, 200, 85); // horizontal line

  let startList = 95;
  let counter = 0;
  doc.setFontSize(12);
  [].forEach((member) => {
    counter++;
    doc.setTextColor(0, 0, 0);
    doc.text(20, startList, member.name);

    // set attendence status
    member.attended ? doc.setTextColor(102, 187, 106) : doc.setTextColor(239, 83, 80);
    doc.text(90, startList, member.attended ? "Yes" : "No");

    // set attendence time if checked in
    doc.setTextColor(0, 0, 0);
    doc.text(150, startList, member.attended ? member.checkedin_at : "N/A");
    startList += 10;

    // add new page if needed
    if (counter % 25 === 0) {
      doc.addPage();
      startList = 20;
    }
  });
  doc.output("dataurlnewwindow");
  // doc.save("Test.pdf");
};
