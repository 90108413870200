import React from 'react'
import VerticalLayout from '../../layouts/VerticalLayout'
import FullLayout from '../../layouts/FullpageLayout'
import HorizontalLayout from '../../layouts/HorizontalLayout'
import themeConfig from '../../configs/themeConfig'
import { getThemes } from '../Common'

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  horizontal: HorizontalLayout
}

const ContextLayout = React.createContext()

class Layout extends React.Component {
  state = {
    activeLayout: themeConfig.layout,
    width: window.innerWidth,
    lastLayout: null,
    direction: themeConfig.direction,
    navbarType: themeConfig.navbarType
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  handleWindowResize = () => {
    this.updateWidth()
    if (this.state.activeLayout === 'horizontal' && this.state.width <= 1199) {
      this.setState({
        activeLayout: 'vertical',
        lastLayout: 'horizontal'
      })
    }

    if (this.state.lastLayout === 'horizontal' && this.state.width >= 1199) {
      this.setState({
        activeLayout: 'horizontal',
        lastLayout: 'vertical'
      })
    }
  }

  componentDidMount = () => {
    this.getDataApi()
    if (window !== 'undefined') {
      window.addEventListener('resize', this.handleWindowResize)
    }
    this.handleDirUpdate()
    if (this.state.activeLayout === 'horizontal' && this.state.width <= 1199) {
      this.setState({
        activeLayout: 'vertical'
      })
    } else if (
      themeConfig.layout === 'horizontal' &&
      this.state.width >= 1200
    ) {
      this.setState({
        activeLayout: 'horizontal'
      })
    } else {
      this.setState({
        activeLayout: 'vertical'
      })
    }
  }

  getDataApi = () => {
    const theme = getThemes()
    if (theme) {
      themeConfig.layout = theme.layout
      themeConfig.theme = theme.theme
      themeConfig.sidebarCollapsed = theme.sidebarCollapsed
      themeConfig.navbarColor = theme.navbarColor
      themeConfig.navbarType = theme.navbarType
      themeConfig.footerType = theme.footerType
      themeConfig.disableCustomizer = theme.disableCustomizer
      themeConfig.hideScrollToTop = theme.hideScrollToTop
      themeConfig.hideBuyButton = theme.hideBuyButton
      themeConfig.menuTheme = theme.menuTheme
      themeConfig.direction = theme.direction
      themeConfig.locale = theme.locale
    } else {
      themeConfig.layout = 'vertical'
      themeConfig.theme = 'dark'
      themeConfig.sidebarCollapsed = 'true'
      themeConfig.navbarColor = 'primary'
      themeConfig.navbarType = 'static'
      themeConfig.footerType = 'sticky'
      themeConfig.disableCustomizer = 'false'
      themeConfig.hideScrollToTop = 'true'
      themeConfig.hideBuyButton = 'true'
      themeConfig.menuTheme = 'primary'
      themeConfig.direction = 'rtl'
      themeConfig.locale = 'en'
    }
  }

  componentDidUpdate() {
    this.getDataApi()
  }

  handleDirUpdate = () => {
    let dir = this.state.direction
    if (dir === 'ltr') {
      themeConfig.direction = 'ltr'
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
    } else {
      themeConfig.direction = 'rtl'
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
    }
  }

  render() {
    const { children } = this.props
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts['full'],
          VerticalLayout: layouts['vertical'],
          horizontalLayout: layouts['horizontal'],
          switchLayout: layout => {
            this.setState({ activeLayout: layout })
          },
          switchDir: dir => {
            this.setState({ direction: dir })
          }
        }}>
        {children}
      </ContextLayout.Provider>
    )
  }
}

export { Layout, ContextLayout }
