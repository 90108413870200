import React from "react"
import { IntlProvider } from "react-intl"
import themeConfig from "../../configs/themeConfig"
import flatten from "flat";
import messages_en from "../../assets/data/locales/en.json"
import messages_pt from "../../assets/data/locales/sa.json"
import messages_de from "../../assets/data/locales/id.json"
import messages_fr from "../../assets/data/locales/fr.json"


const menu_messages = {
  en: flatten(messages_en),
  sa: flatten(messages_pt),
  id: flatten(messages_de),
  fr: flatten(messages_fr)
}

const Context = React.createContext()

class IntlProviderWrapper extends React.Component {
  state = {
    locale: themeConfig.locale,
    messages: menu_messages[themeConfig.locale]
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            this.setState({
              locale: language,
              messages: menu_messages[language]
            })
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="sa"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
