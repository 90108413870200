import React from "react";
import * as Icon from "react-feather";
import { getUser } from "../utility/Common";

let adminNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    children: [
      {
        id: "home",
        title: "Homes",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home",
      },
      {
        id: "connection",
        title: "ConnectionMenue",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home/connection",
      },
      {
        id: "user_view",
        title: "View My Profile",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/app/user/user-view",
      },
      {
        id: "supervisor",
        title: "supervision.goto.link",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home/information",
      },
    ],
  },
  {
    id: "users",
    title: "User",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "list",
        title: "List",
        type: "item",
        icon: <Icon.Users size={14} />,
        navLink: "/app/user/list",
      },
      {
        id: "add",
        title: "Add New Employee",
        type: "item",
        icon: <Icon.UserPlus size={14} />,
        navLink: "/app/user/add",
      },
    ],
  },
  {
    id: "task",
    title: "Task",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    children: [
      {
        id: "listTask",
        title: "Task List",
        type: "item",
        icon: <Icon.List size={15} />,
        navLink: "/app/task/list",
      },
      {
        id: "todo",
        title: "Todo",
        type: "item",
        icon: <Icon.Zap size={20} />,
        navLink: "/todo/:filter",
        filterBase: "/todo/all",
      },
    ],
  },
  {
    id: "finance",
    title: "Finance",
    type: "collapse",
    icon: <Icon.BookOpen size={16} />,
    children: [
      {
        id: "bookkeeping",
        title: "Bookkeeping",
        type: "item",
        icon: <Icon.Inbox size={15} />,
        navLink: "/bookkeeping",
      },
      {
        id: "money_request",
        title: "Money Request",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/money-request",
      },
      {
        id: "finance_report",
        title: "Finance Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/finance-report",
      },
    ],
  },
  {
    id: "report",
    title: "Report",
    type: "collapse",
    icon: <Icon.Tablet size={16} />,
    children: [
      {
        id: "money_report",
        title: "Money Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/money-report",
      },
      {
        id: "task_report",
        title: "Task Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/task-report",
      },
      {
        id: "employee_report",
        title: "Employee Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/employee-report",
      },
      {
        id: "manual_report",
        title: "Manual Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/manual-report",
      },
    ],
  },
  {
    id: "template",
    title: "Template",
    type: "item",
    icon: <Icon.FileText size={20} />,
    navLink: "/app/template",
  },
  {
    id: "email",
    title: "Email",
    type: "item",
    icon: <Icon.Mail size={20} />,
    navLink: "/email/:filter",
    filterBase: "/email/inbox",
  },
  {
    id: "chat",
    title: "Chat",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/chat",
  },
  {
    id: "fileManager",
    title: "File Manager",
    type: "item",
    icon: <Icon.Save size={20} />,
    navLink: "/file-manager",
    collapsed: true,
  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    navLink: "/calendar",
  },
  {
    id: "documentation",
    title: "Information about the system",
    icon: <Icon.Info size={20} />,
    type: "item",
    navLink: "/sysinfo",
  },
];

let managerNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    children: [
      {
        id: "home",
        title: "Homes",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home",
      },
      {
        id: "user_view",
        title: "View My Profile",
        type: "item",
        icon: <Icon.Users size={14} />,
        navLink: "/app/user/user-view",
      },
      {
        id: "supervisor",
        title: "supervision.goto.link",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home/information",
      },
    ],
  },
  {
    id: "task",
    title: "Task",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    children: [
      {
        id: "listTask",
        title: "Task List",
        type: "item",
        icon: <Icon.Archive size={14} />,
        navLink: "/app/task/list",
      },
      {
        id: "todo",
        title: "Todo",
        type: "item",
        icon: <Icon.Zap size={20} />,
        navLink: "/todo/:filter",
        filterBase: "/todo/all",
      },
    ],
  },
  {
    id: "finance",
    title: "Finance",
    type: "collapse",
    icon: <Icon.BookOpen size={16} />,
    children: [
      {
        id: "money_request",
        title: "Money Request",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/money-request",
      },
      {
        id: "finance_report",
        title: "Finance Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/finance-report",
      },
    ],
  },
  {
    id: "report",
    title: "Report",
    type: "collapse",
    icon: <Icon.Tablet size={16} />,
    children: [
      {
        id: "money_report",
        title: "Money Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/money-report",
      },
      {
        id: "task_report",
        title: "Task Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/task-report",
      },
      {
        id: "employee_report",
        title: "Employee Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/employee-report",
      },
      {
        id: "manual_report",
        title: "Manual Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/manual-report",
      },
    ],
  },
  {
    id: "template",
    title: "Template",
    type: "item",
    icon: <Icon.FileText size={20} />,
    navLink: "/app/template",
  },
  {
    id: "email",
    title: "Email",
    type: "item",
    icon: <Icon.Mail size={20} />,
    navLink: "/email/:filter",
    filterBase: "/email/inbox",
  },
  {
    id: "chat",
    title: "Chat",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/chat",
  },
  {
    id: "fileManager",
    title: "File Manager",
    type: "item",
    icon: <Icon.Save size={20} />,
    navLink: "/file-manager",
    collapsed: true,
  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    navLink: "/calendar",
  },
  {
    id: "documentation",
    title: "Information about the system",
    icon: <Icon.Info size={20} />,
    type: "item",
    navLink: "/sysinfo",
  },
];

let subManagerNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    children: [
      {
        id: "home",
        title: "Homes",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home",
      },
      {
        id: "user_view",
        title: "View My Profile",
        type: "item",
        icon: <Icon.Users size={14} />,
        navLink: "/app/user/user-view",
      },
    ],
  },
  {
    id: "task",
    title: "Task",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    children: [
      {
        id: "listTask",
        title: "Task List",
        type: "item",
        icon: <Icon.Archive size={14} />,
        navLink: "/app/task/list",
      },
      {
        id: "todo",
        title: "Todo",
        type: "item",
        icon: <Icon.Zap size={20} />,
        navLink: "/todo/:filter",
        filterBase: "/todo/all",
      },
    ],
  },
  {
    id: "finance",
    title: "Finance",
    type: "collapse",
    icon: <Icon.BookOpen size={16} />,
    children: [
      {
        id: "money_request",
        title: "Money Request",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/money-request",
      },
      {
        id: "finance_report",
        title: "Finance Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/finance-report",
      },
    ],
  },
  {
    id: "report",
    title: "Report",
    type: "collapse",
    icon: <Icon.Tablet size={16} />,
    children: [
      {
        id: "task_report",
        title: "Task Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/task-report",
      },
      {
        id: "manual_report",
        title: "Manual Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/manual-report",
      },
    ],
  },
  {
    id: "template",
    title: "Template",
    type: "item",
    icon: <Icon.FileText size={20} />,
    navLink: "/app/template",
  },
  {
    id: "email",
    title: "Email",
    type: "item",
    icon: <Icon.Mail size={20} />,
    navLink: "/email/:filter",
    filterBase: "/email/inbox",
  },
  {
    id: "chat",
    title: "Chat",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/chat",
  },
  {
    id: "fileManager",
    title: "File Manager",
    type: "item",
    icon: <Icon.Save size={20} />,
    navLink: "/file-manager",
    collapsed: true,
  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    navLink: "/calendar",
  },
  {
    id: "documentation",
    title: "Information about the system",
    icon: <Icon.Info size={20} />,
    type: "item",
    navLink: "/sysinfo",
  },
];

let accountantNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    children: [
      {
        id: "home",
        title: "Homes",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home",
      },
      {
        id: "user_view",
        title: "View My Profile",
        type: "item",
        icon: <Icon.Users size={14} />,
        navLink: "/app/user/user-view",
      },
      {
        id: "bookkeeping",
        title: "Bookkeeping",
        type: "item",
        icon: <Icon.Inbox size={15} />,
        navLink: "/bookkeeping",
      },
    ],
  },
  {
    id: "task",
    title: "Task",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    children: [
      {
        id: "listTask",
        title: "Task List",
        type: "item",
        icon: <Icon.Archive size={14} />,
        navLink: "/app/task/list",
      },
      {
        id: "todo",
        title: "Todo",
        type: "item",
        icon: <Icon.Zap size={20} />,
        navLink: "/todo/:filter",
        filterBase: "/todo/all",
      },
    ],
  },
  {
    id: "finance",
    title: "Finance",
    type: "collapse",
    icon: <Icon.BookOpen size={16} />,
    children: [
      {
        id: "money_request",
        title: "Money Request",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/money-request",
      },
      {
        id: "finance_report",
        title: "Finance Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/finance-report",
      },
      {
        id: "money_report",
        title: "Money Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/money-report",
      },
    ],
  },
  {
    id: "report",
    title: "Report",
    type: "collapse",
    icon: <Icon.Tablet size={16} />,
    children: [
      {
        id: "task_report",
        title: "Task Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/task-report",
      },
      {
        id: "manual_report",
        title: "Manual Report",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/manual-report",
      },
    ],
  },
  {
    id: "template",
    title: "Template",
    type: "item",
    icon: <Icon.FileText size={20} />,
    navLink: "/app/template",
  },
  {
    id: "email",
    title: "Email",
    type: "item",
    icon: <Icon.Mail size={20} />,
    navLink: "/email/:filter",
    filterBase: "/email/inbox",
  },
  {
    id: "chat",
    title: "Chat",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/chat",
  },
  {
    id: "fileManager",
    title: "File Manager",
    type: "item",
    icon: <Icon.Save size={20} />,
    navLink: "/file-manager",
    collapsed: true,
  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    navLink: "/calendar",
  },
  {
    id: "documentation",
    title: "Information about the system",
    icon: <Icon.Info size={20} />,
    type: "item",
    navLink: "/sysinfo",
  },
];

let employeeNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    children: [
      {
        id: "home",
        title: "Homes",
        type: "item",
        icon: <Icon.Circle size={13} />,
        navLink: "/home",
      },
      {
        id: "user_view",
        title: "View My Profile",
        type: "item",
        icon: <Icon.Users size={14} />,
        navLink: "/app/user/user-view",
      },
    ],
  },
  {
    id: "task",
    title: "Task",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    children: [
      {
        id: "listTask",
        title: "Task List",
        type: "item",
        icon: <Icon.Archive size={14} />,
        navLink: "/app/task/list",
      },
      {
        id: "todo",
        title: "Todo",
        type: "item",
        icon: <Icon.Zap size={20} />,
        navLink: "/todo/:filter",
        filterBase: "/todo/all",
      },
    ],
  },
  {
    id: "manual_report",
    title: "Manual Report",
    type: "item",
    icon: <Icon.Tablet size={16} />,
    navLink: "/manual-report",
  },
  {
    id: "money_request",
    title: "Money Request",
    type: "item",
    icon: <Icon.BookOpen size={16} />,
    navLink: "/money-request",
  },
  {
    id: "template",
    title: "Template",
    type: "item",
    icon: <Icon.FileText size={20} />,
    navLink: "/app/template",
  },
  {
    id: "email",
    title: "Email",
    type: "item",
    icon: <Icon.Mail size={20} />,
    navLink: "/email/:filter",
    filterBase: "/email/inbox",
  },
  {
    id: "chat",
    title: "Chat",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/chat",
  },
  {
    id: "fileManager",
    title: "File Manager",
    type: "item",
    icon: <Icon.Save size={20} />,
    navLink: "/file-manager",
    collapsed: true,
  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    navLink: "/calendar",
  },
  {
    id: "documentation",
    title: "Information about the system",
    icon: <Icon.Info size={20} />,
    type: "item",
    navLink: "/sysinfo",
  },
];

export default () => {
  let user = getUser();
  switch (user.role) {
    case "admin":
      return adminNavigation;
    case "manager":
      return managerNavigation;
    case "sub_manager":
      return subManagerNavigation;
    case "accountant":
      return accountantNavigation;
    default:
      return employeeNavigation;
  }
};
