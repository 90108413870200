import React, { createContext, useReducer, useMemo, useContext } from "react";
import { serverBase } from "../utility/axios";
import { isFileOverLimit, user_usage_max } from "../utility/helper";
import { useAuth } from "./authContext";

const FileContext = createContext();
const initialFileState = [];

const FileReducer = (state, action) => {
  switch (action.type) {
    case "SET_FILE":
      return action.payload;
    case "UNSET_FILE":
      return initialFileState;
    case "DELETE_FILE":
      return state.filter((s) => s._id !== action.payload);
    default:
      return initialFileState;
  }
};

export const FileProvider = (props) => {
  const [state, dispatch] = useReducer(FileReducer, initialFileState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <FileContext.Provider value={value} {...props} />;
};

export const useFile = () => {
  const context = useContext(FileContext);
  const { authState } = useAuth();
  if (!context) {
    throw new Error(`useFile must be used within a FileProvider`);
  }
  const [state, dispatch] = context;

  const getAllFile = async (_id) => {
    const res = await serverBase.get(`file/list?_id=${_id}`);
    if (res.status === 200 && res.data) {
      dispatch({ type: "SET_FILE", payload: res.data });
    }
  };

  const dumpAllFile = () => {
    dispatch({ type: "UNSET_FILE" });
  };

  const uploadFile = async (file) => {
    const { user_usage, maximum_storage } = await user_usage_max(authState._id);
    if (!isFileOverLimit(file, user_usage, maximum_storage)) {
      let formData = new FormData();
      formData.append("_id", authState._id);
      formData.append("role", authState.role);
      formData.append("attach", file);
      const res = await serverBase
        .post("file/upload-files", formData, {
          "Content-Type": "multipart/form-data",
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data);
          }
        });
      if (res?.status === 200) {
        getAllFile(authState._id);
        return res;
      }
      return res;
    }
  };

  const deleteFile = async (data) => {
    const res = await serverBase.post(`file/delete`, data).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
    if (res.status === 200) {
      dispatch({ type: "SET_FILE", payload: res.data });
    }
    return res;
  };

  const shareFile = async (data) => {
    const res = await serverBase.patch(`file/share`, data).catch((err) => {
      if (err.response) {
        alert(err.response.data);
      }
    });
    if (res?.status === 200) {
      getAllFile(authState._id);
      return res;
    }
    return res;
  };

  return {
    files: state,
    getAllFile,
    dumpAllFile,
    deleteFile,
    shareFile,
    uploadFile,
  };
};
