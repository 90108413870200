export const defaultThemeConfig = {
  layout: "vertical",
  theme: "dark",
  sidebarCollapsed: "true",
  navbarColor: "primary",
  navbarType: "static",
  footerType: "sticky",
  disableCustomizer: "false",
  hideScrollToTop: "true",
  hideBuyButton: "true",
  menuTheme: "primary",
  direction: "rtl",
  locale: "sa",
};

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getThemes = () => {
  const userStr = sessionStorage.getItem("theme");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getTheme = () => {
  const obj = JSON.stringify(defaultThemeConfig);
  return JSON.parse(obj);
};

export const getLvl = () => {
  const userStr = sessionStorage.getItem("lvl");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

/*// return the token from the session storage
export const tokenTimeOut = () => {
    return sessionStorage.removeItem('token') || null
}
*/
// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("theme", JSON.stringify(user.theme));
};

export const updateSessionTheme = (type, value) => {
  let theme = sessionStorage.getItem("theme");
  let obj;
  if (theme) {
    obj = JSON.parse(theme);
  } else {
    obj = { ...defaultThemeConfig };
  }
  obj[type] = value;
  sessionStorage.setItem("theme", JSON.stringify(obj));
};

export const getExtension = (name) => {
  let split = name.split(".");
  if (split && split.length > 1) {
    return `${split[split.length - 1]}`;
  } else {
    return `jpg`;
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
